import _rangeToTextRange from "./rangeToTextRange";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var rangeToTextRange = _rangeToTextRange;
var doc = _global.document;
var body = doc.body;
var GetSelectionProto = GetSelection.prototype;

function GetSelection(selection) {
  var self = this;
  var range = selection.createRange();
  this._selection = selection;
  this._ranges = [];

  if (selection.type === "Control") {
    updateControlSelection(self);
  } else if (isTextRange(range)) {
    updateFromTextRange(self, range);
  } else {
    updateEmptySelection(self);
  }
}

GetSelectionProto.removeAllRanges = function () {
  var textRange;

  try {
    this._selection.empty();

    if (this._selection.type !== "None") {
      textRange = body.createTextRange();
      textRange.select();

      this._selection.empty();
    }
  } catch (e) {}

  updateEmptySelection(this);
};

GetSelectionProto.addRange = function (range) {
  if (this._selection.type === "Control") {
    addRangeToControlSelection(this, range);
  } else {
    rangeToTextRange(range).select();
    this._ranges[0] = range;
    this.rangeCount = 1;
    this.isCollapsed = this._ranges[0].collapsed;
    updateAnchorAndFocusFromRange(this, range, false);
  }
};

GetSelectionProto.setRanges = function (ranges) {
  this.removeAllRanges();
  var rangeCount = ranges.length;

  if (rangeCount > 1) {
    createControlSelection(this, ranges);
  } else if (rangeCount) {
    this.addRange(ranges[0]);
  }
};

GetSelectionProto.getRangeAt = function (index) {
  if (index < 0 || index >= this.rangeCount) {
    throw new Error("getRangeAt(): index out of bounds");
  } else {
    return this._ranges[index].cloneRange();
  }
};

GetSelectionProto.removeRange = function (range) {
  if (this._selection.type !== "Control") {
    removeRangeManually(this, range);
    return;
  }

  var controlRange = this._selection.createRange();

  var rangeElement = getSingleElementFromRange(range);
  var newControlRange = body.createControlRange();
  var el;
  var removed = false;

  for (var i = 0, len = controlRange.length; i < len; ++i) {
    el = controlRange.item(i);

    if (el !== rangeElement || removed) {
      newControlRange.add(controlRange.item(i));
    } else {
      removed = true;
    }
  }

  newControlRange.select();
  updateControlSelection(this);
};

GetSelectionProto.eachRange = function (fn, returnValue) {
  var i = 0;
  var len = this._ranges.length;

  for (i = 0; i < len; ++i) {
    if (fn(this.getRangeAt(i))) {
      return returnValue;
    }
  }
};

GetSelectionProto.getAllRanges = function () {
  var ranges = [];
  this.eachRange(function (range) {
    ranges.push(range);
  });
  return ranges;
};

GetSelectionProto.setSingleRange = function (range) {
  this.removeAllRanges();
  this.addRange(range);
};

function createControlSelection(sel, ranges) {
  var controlRange = body.createControlRange();

  for (var i = 0, el, len = ranges.length; i < len; ++i) {
    el = getSingleElementFromRange(ranges[i]);

    try {
      controlRange.add(el);
    } catch (e) {
      throw new Error("setRanges(): Element could not be added to control selection");
    }
  }

  controlRange.select();
  updateControlSelection(sel);
}

function removeRangeManually(sel, range) {
  var ranges = sel.getAllRanges();
  sel.removeAllRanges();

  for (var i = 0, len = ranges.length; i < len; ++i) {
    if (!isSameRange(range, ranges[i])) {
      sel.addRange(ranges[i]);
    }
  }

  if (!sel.rangeCount) {
    updateEmptySelection(sel);
  }
}

function updateAnchorAndFocusFromRange(sel, range) {
  var anchorPrefix = "start";
  var focusPrefix = "end";
  sel.anchorNode = range[anchorPrefix + "Container"];
  sel.anchorOffset = range[anchorPrefix + "Offset"];
  sel.focusNode = range[focusPrefix + "Container"];
  sel.focusOffset = range[focusPrefix + "Offset"];
}

function updateEmptySelection(sel) {
  sel.anchorNode = sel.focusNode = null;
  sel.anchorOffset = sel.focusOffset = 0;
  sel.rangeCount = 0;
  sel.isCollapsed = true;
  sel._ranges.length = 0;
}

function rangeContainsSingleElement(rangeNodes) {
  if (!rangeNodes.length || rangeNodes[0].nodeType !== 1) {
    return false;
  }

  for (var i = 1, len = rangeNodes.length; i < len; ++i) {
    if (!isAncestorOf(rangeNodes[0], rangeNodes[i])) {
      return false;
    }
  }

  return true;
}

function getSingleElementFromRange(range) {
  var nodes = range.getNodes();

  if (!rangeContainsSingleElement(nodes)) {
    throw new Error("getSingleElementFromRange(): range did not consist of a single element");
  }

  return nodes[0];
}

function isTextRange(range) {
  return range && range.text !== void 0;
}

function updateFromTextRange(sel, range) {
  sel._ranges = [range];
  updateAnchorAndFocusFromRange(sel, range, false);
  sel.rangeCount = 1;
  sel.isCollapsed = range.collapsed;
}

function updateControlSelection(sel) {
  sel._ranges.length = 0;

  if (sel._selection.type === "None") {
    updateEmptySelection(sel);
  } else {
    var controlRange = sel._selection.createRange();

    if (isTextRange(controlRange)) {
      updateFromTextRange(sel, controlRange);
    } else {
      sel.rangeCount = controlRange.length;
      var range;

      for (var i = 0; i < sel.rangeCount; ++i) {
        range = doc.createRange();
        range.selectNode(controlRange.item(i));

        sel._ranges.push(range);
      }

      sel.isCollapsed = sel.rangeCount === 1 && sel._ranges[0].collapsed;
      updateAnchorAndFocusFromRange(sel, sel._ranges[sel.rangeCount - 1], false);
    }
  }
}

function addRangeToControlSelection(sel, range) {
  var controlRange = sel._selection.createRange();

  var rangeElement = getSingleElementFromRange(range);
  var newControlRange = body.createControlRange();

  for (var i = 0, len = controlRange.length; i < len; ++i) {
    newControlRange.add(controlRange.item(i));
  }

  try {
    newControlRange.add(rangeElement);
  } catch (e) {
    throw new Error("addRange(): Element could not be added to control selection");
  }

  newControlRange.select();
  updateControlSelection(sel);
}

function isSameRange(left, right) {
  return left.startContainer === right.startContainer && left.startOffset === right.startOffset && left.endContainer === right.endContainer && left.endOffset === right.endOffset;
}

function isAncestorOf(ancestor, descendant) {
  var node = descendant;

  while (node.parentNode) {
    if (node.parentNode === ancestor) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}

function getSelection() {
  return new GetSelection(_global.document.selection);
}

exports = getSelection;
export default exports;