import _getSelection from "./getSelection";
import _rangeToTextRange from "./rangeToTextRange";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var getSelection = _getSelection;
var rangeToTextRange = _rangeToTextRange;
var doc = _global.document;

function setSelection(p) {
  if (doc.createRange) {
    modernSelection();
  } else {
    oldSelection();
  }

  function modernSelection() {
    var sel = getSelection();
    var range = doc.createRange();

    if (!p.startContainer) {
      return;
    }

    if (p.endContainer) {
      range.setEnd(p.endContainer, p.endOffset);
    } else {
      range.setEnd(p.startContainer, p.startOffset);
    }

    range.setStart(p.startContainer, p.startOffset);
    sel.removeAllRanges();
    sel.addRange(range);
  }

  function oldSelection() {
    rangeToTextRange(p).select();
  }
}

exports = setSelection;
export default exports;