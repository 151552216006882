import _getSelectionRaw from "./getSelectionRaw";
import _getSelectionNullOp from "./getSelectionNullOp";
import _getSelectionSynthetic from "./getSelectionSynthetic";
import _isHost from "./isHost";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var getSelection;
var doc = _global.document;
var getSelectionRaw = _getSelectionRaw;
var getSelectionNullOp = _getSelectionNullOp;
var getSelectionSynthetic = _getSelectionSynthetic;
var isHost = _isHost;

if (isHost.method(_global, "getSelection")) {
  getSelection = getSelectionRaw;
} else if (typeof doc.selection === "object" && doc.selection) {
  getSelection = getSelectionSynthetic;
} else {
  getSelection = getSelectionNullOp;
}

exports = getSelection;
export default exports;