var exports = {};

function isHostMethod(host, prop) {
  var type = typeof host[prop];
  return type === "function" || !!(type === "object" && host[prop]) || type === "unknown";
}

function isHostProperty(host, prop) {
  return typeof host[prop] !== "undefined";
}

function many(fn) {
  return function areHosted(host, props) {
    var i = props.length;

    while (i--) {
      if (!fn(host, props[i])) {
        return false;
      }
    }

    return true;
  };
}

exports = {
  method: isHostMethod,
  methods: many(isHostMethod),
  property: isHostProperty,
  properties: many(isHostProperty)
};
export default exports;