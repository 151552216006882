var exports = {};

function noop() {}

function getSelectionNullOp() {
  return {
    removeAllRanges: noop,
    addRange: noop
  };
}

exports = getSelectionNullOp;
export default exports;