import _getSelection from "./getSelection";
import _setSelection from "./setSelection";
var exports = {};
var getSelection = _getSelection;
var setSelection = _setSelection;
exports = {
  get: getSelection,
  set: setSelection
};
export default exports;
export const get = exports.get,
      set = exports.set;